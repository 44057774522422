// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const MyPerfApi = {
	adminList: '/v3/Admin/List',
	rateList: '/v3/WarningRate/List',
}

export function adminList(data) {
	return axios({
		url: MyPerfApi.adminList,
		method: 'post',
		data:data
	})
}

export function rateList(data) {
	return axios({
		url: MyPerfApi.rateList,
		method: 'post',
		data:data
	})
}
