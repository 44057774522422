<!-- 绩效管理 -->
<template>
    <div class="Perf">
        <div class="Perf-T">
            <div class="callthepoliceL">
                <Breadcrumb></Breadcrumb>
                <div class="RightsManagement-searchs" style="margin-left: 20px;">
                    <el-input v-model="Parame.T_name" placeholder="请输入姓名回车查询" @keyup.enter.native="upadminListApi"></el-input>
                </div>
            </div>
            <div class="callthepoliceR"></div>
        </div>
        <div class="Perf-B">
            <div class="Perf-B-left">
                <el-menu v-if="navData!=null" :default-active="defActiove" style="border-right:none">
                    <el-menu-item :index="item.T_uuid" v-for="(item,j) in navData" :key='j' @click="defActiove = item.T_uuid">
                        <el-popover placement="right" width="70" trigger="hover" :content="item.T_power_name">
                            <div slot="reference" style="display: flex;align-items: center;">
                                <i class="el-icon-arrow-right"></i>
                                <div slot="title" :title="item.T_name" style="width: 180px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.T_name }}</div>
                            </div>
                        </el-popover>
                    </el-menu-item>
                </el-menu>
                <el-empty v-else description="暂无可展示数据哦" :image-size="100"></el-empty>
            </div>
            <div class="Perf-B-right">
                <div style="margin-bottom: 20px;">
                    <el-form :inline="true">
                        <el-form-item label="选择年">
                            <el-date-picker v-model="Parame1.T_year" type="year" value-format="yyyy" placeholder="选择年"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="选择月">
                            <el-date-picker class="monthClass" v-model="Parame1.T_month" type="month" format="M月" value-format="MM"
                                placeholder="选择月份">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="uprateListApi">立即查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="图表展示" name="first">
                        <div id="main" style=" height: 500px"></div>
                        
                    </el-tab-pane>
                    <el-tab-pane label="表格展示" name="second">
                        <el-table :data="seriesData" style="width: 100%;margin-top: 1.5%;">
                            <el-table-column type="index" width="50" label="序号">
                            </el-table-column>
                            <el-table-column prop="T_date" label="日期" width="180">
                            </el-table-column>
                            <el-table-column prop="T_device_num" label="设备数量" width="180">
                            </el-table-column>
                            <el-table-column prop="T_warning_num" label="报警数量">
                            </el-table-column>
                            <el-table-column prop="value" label="设备报警率">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import {adminList,rateList} from "@/api/PerfManagement"
export default {
    data() {
        return {
            defActiove:null,
            option:null,
            Parame:{
                T_name:'',
                page:1,
                page_z:999999,
                T_bind_company:1,
            },
            navData:[],
            Parame1:{
                T_uuid:'',
                T_year:null,
                T_month:null,
            },
            myChart:null,
            activeName: 'first',
            seriesData: []
        }
    },
    watch:{
        defActiove(newVal){
            ////console.log('变化',newVal)
            this.uprateListApi()
        }
    },
    mounted() {
        this.drawChart();
        this.upadminListApi()
    },

    methods: {
       
        upadminListApi(){
            adminList(this.Parame).then(res=>{
                let {Data:resIt} = res.data.Data
                ////console.log('用户',resIt,this.defActiove)
                this.navData = resIt
                this.navData!=null?this.defActiove = this.navData[0].T_uuid:this.defActiove = null
            })
        },
        uprateListApi(){
            this.Parame1.T_uuid = this.defActiove
            ////console.log('提交信息',this.Parame1)
            let obj = {...this.Parame1}
            if(this.Parame1.T_year!=null && this.Parame1.T_month==null){//选了年 没有选月  把月改为0
                obj.T_month = 0
                this.upDataApi(obj)
            }else if(this.Parame1.T_month!=null && this.Parame1.T_year==null){ //选了月  但是没选年  提示必须选择年
                this.$notify({
                    title: '提示',
                    message: '哎呀！必须选择年份哦',
                    type: 'warning'
                });
                return
            }else{
                this.upDataApi(obj)
            }

            
        },
        upDataApi(obj){
            rateList(obj).then(res=>{
                ////console.log('信息',res)
                if(res.data.Code==200){
                    let arr = res.data.Data
                    if(arr==null){
                        this.$notify({
                            title: '提示',
                            message: '哎呀！没有可展示绩效哦',
                            type: 'warning'
                        });
                    }
                    this.seriesData = arr
                    this.option = {
                        xAxis: {
                            data: arr==null?[]:arr.map((it)=>it.T_date),
                        },
                        series:[{
                            type: "bar",//line曲线  bar柱状图
                            itemStyle: {
                                color: '#409EFF'
                            },
                            data: arr 
                        }]
                    } 
                    this.myChart.setOption(this.option)
                }
            })
        },
        drawChart() {
            this.myChart = this.$echarts.init(document.getElementById("main"));
            this.option = {
                title: {
                    text: "",
                },
                 //柱形图和折线图相互切换
                 toolbox: {
                    show : true,
                    feature : { 
                        magicType: {
                            show: true, 
                            type: ['line','bar']/* line是折线图，bar是柱形图*/
                        },
                    }
                },
               
                tooltip: {
                    // trigger: 'axis',
                    // axisPointer: {
                    //     type: 'cross',
                    //     label: {
                    //         backgroundColor: '#283b56'
                    //     }
                    // },
                    formatter: function (params) {
                        var tipHtml = '';
                        tipHtml = `<div class="infoPop">
                                    <div class="infoTitle">设备数量：${params.data.T_device_num}</div>
                                    <div class="infoTitle">报警数量：${params.data.T_warning_num}</div>
                                    <div class="infoTitle">设备报警率：${params.data.value}</div>
                                    </div>`;
                        return tipHtml;
                    }
                },
                grid: {
                    left: '2.5%',
                    right: '2.5%',
                    bottom: '0%',
                    top:'10%',
                    containLabel: true
                },
                legend: {
                    data: ["销量"],
                },
                xAxis: {
                    data: [],
                },
                yAxis: {},
                
            };
            // 使用刚指定的配置项和数据显示图表。
            this.myChart.setOption(this.option);
            window.onresize = this.myChart.resize;
        },

    }
}
</script>
<style lang="scss">
.el-date-picker__header {
    display: none;
}
</style>
<style lang="scss">
.Perf {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    .Perf-T {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(#fff, 1);
        height: 70px;
        padding: 15px 10px;
        box-shadow: 5px 5px 10px -10px #000;

        .callthepoliceL {
            display: flex;
            align-items: center;
        }
    }

    &-B {
        flex: 1;
        background: rgba(#fff, 1);
        margin: 10px;
        padding: 20px 0;
        height: 100%;
        display: flex;
        overflow: hidden;
        &-left {
            flex-shrink: 0;
            width: 220px;
            border-right: solid 1px #e6e6e6;
            margin-right: 20px;
            overflow-y: auto;
        }

        &-right {
            flex: 1;
        }
    }
}
</style>